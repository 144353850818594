<template>
    <chart-of-account-wrapper>
        <template v-slot:child>
            <manage-account-header />
            <div class="w-90 center mt4">
                <div class="flex justify-between items-center manageHeader secPad">
                    <div class="b w-50">Account</div>
                    <div class="b w-50">Description</div>
                    <div class="b">
                        <!-- Action -->
                    </div>
                </div>
                <section class="secPad">
                    <div class="flex justify-between items-center secFirst">
                        <div class="pl3">Non Current Assets</div>
                        <div></div>
                        <div>
                            <!-- <button class="flex items-center" style="gap: 10px">
                                <span><img :src="require('@/assets/images/chartCircle.svg')" /></span><span>Add account</span>
                            </button> -->
                        </div>
                    </div>
                    <div class="flex justify-between items-center secPad">
                        <div class="w-50">Equipment</div>
                        <div class="w-50 tl">Office equipment eg Printers, computers scanners, electronic equipment</div>
                        <!-- <div class="w-50 tr">
                            <img :src="require('@/assets/images/chartPen.svg')" />
                        </div> -->
                    </div>
                    <div class="flex justify-between items-center secPad">
                        <div class="w-50">Furniture and Fittings</div>
                        <div class="w-50 tl">Chairs, desks, partitions, cabinets etct</div>
                        <!-- <div class="w-50 tr">
                            <img :src="require('@/assets/images/chartPen.svg')" />
                        </div> -->
                    </div>
                    <div class="flex justify-between items-center secPad">
                        <div class="w-50">Plant and Machinery</div>
                        <div class="w-50 tl">Machines used in production, genarators etc</div>
                        <!-- <div class="w-50 tr">
                            <img :src="require('@/assets/images/chartPen.svg')" />
                        </div> -->
                    </div>
                </section>
                <section class="secPad">
                    <div class="flex justify-between items-center secFirst">
                        <div class="pl3">Current Assets</div>
                        <div></div>
                        <div>
                            <!-- <button class="flex items-center" style="gap: 10px">
                                <span><img :src="require('@/assets/images/chartCircle.svg')" /></span><span>Add account</span>
                            </button> -->
                        </div>
                    </div>
                    <div class="flex justify-between items-center secPad">
                        <router-link  class="w-50 tl" :to="{ name: 'ChartView' }">
                            <div class="w-50">Trade Receivables</div>
                        </router-link>
                        <div class="w-50 tl">Amounts outstanding on sales made to customers</div>
                        <!-- <div class="w-50 tr">
                            <img :src="require('@/assets/images/chartPen.svg')" />
                        </div> -->
                    </div>
                    <div class="flex justify-between items-center secPad">
                        <div class="w-50">Inventory</div>
                        <div class="w-50 tl">Items held for sale in the ordinary course of business eg Raw material, finished goods, work in progress</div>
                        <!-- <div class="w-50 tr">
                            <img :src="require('@/assets/images/chartPen.svg')" />
                        </div> -->
                    </div>
                    <div class="flex justify-between items-center secPad">
                        <div class="w-50">Prepaid Expenses</div>
                        <div class="w-50 tl">Amounts paid for expenses in advance</div>
                        <!-- <div class="w-50 tr">
                            <img :src="require('@/assets/images/chartPen.svg')" />
                        </div> -->
                    </div>
                    <div 
                        v-for="(account, index) in bankAccounts"
                        :key="index"
                        :value="account?.aliasName || account.bankName"
                        class="flex justify-between items-center secPad"
                    >
                        <div class="w-50 b">
                            <router-link :to="{ name: 'AssetsBank', query: { bank: account?.aliasName || account.bankName } }">
                                {{ account?.aliasName || account?.bankName }}
                            </router-link>
                        </div>
                        <div class="w-50 tl">Available bank balance</div>
                        <!-- <div class="w-50 tr">
                            <img :src="require('@/assets/images/chartPen.svg')" />
                        </div> -->
                    </div>
                    <div class="flex justify-between items-center secPad">
                        <div class="w-50 b">
                            <router-link :to="{ name: 'AssetsCash' }">Cash</router-link>
                        </div>
                        <div class="w-50 tl">Available cash balance</div>
                        <!-- <div class="w-50 tr">
                            <img :src="require('@/assets/images/chartPen.svg')" />
                        </div> -->
                    </div>
                    <div class="flex justify-between items-center secPad">
                        <div class="w-50">Loan</div>
                        <div class="w-50 tl">Loan given out by the company</div>
                        <!-- <div class="w-50 tr">
                            <img :src="require('@/assets/images/chartPen.svg')" />
                        </div> -->
                    </div>
                </section>
            </div>
        </template>
    </chart-of-account-wrapper>
</template>
<script>
import { onMounted, computed } from 'vue'
import { useStore } from 'vuex'
import ChartOfAccountWrapper from '../widgets/ChartOfAccountWrapper.vue'
import ManageAccountHeader from '../widgets/ManageAccountHeader.vue'
export default {
    name: 'ManageAssets',
    components: { ManageAccountHeader, ChartOfAccountWrapper },
    setup() {
        const store = useStore()
        const bankAccounts = computed(() => store.state?.Settings?.bankAccounts)

        onMounted(async () => {
            await store.dispatch('Settings/getBankAccounts')
        })
        return {
            bankAccounts
        }
    },
}
</script>
<style scoped>
.secPad div{
    align-items: flex-start !important;
}
</style>
